import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Gallery } from "./components/gallery";
import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import toast, { Toaster } from 'react-hot-toast';
import useLocalStorage from './hooks/useLocalstorage';
const BIRTHDAY = '17-10-1999';

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  useLocalStorage();
  const [password, setPassword] = useLocalStorage("PASSWORD", "");
  // const [password, setPassword] = useState();
  console.log('password: ', password);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      console.log('e: ', e);
      console.log('do validate');
      const birthdayInput = e.target.value;
      console.log('birthday: ', birthdayInput);
      if(birthdayInput !== birthdayInput){
        // toast("Sai Password rồi bạn ei :))")
        // notify()
      }
      setPassword(birthdayInput)
    }
  }

  if(!password || password !== BIRTHDAY){
    return (
      <>
        <div style={{
          width: window.innerWidth,
          height: window.innerHeight,
          // backgroundColor: "red",
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center"
        }}>
          <input style={{
            padding: 5,
            width: 360,
            fontSize: 20,
          }} type="text"
          placeholder="Nhập ngày sinh KLinh (DD-MM-YYYY)"
          onKeyDown={handleKeyDown}
          />
        </div>
        
      </>
    )
  }

  return (
    <>
      <Toaster />
      <div>
        <Navigation />
        <Header data={landingPageData.Header} />
        {/* <Features data={landingPageData.Features} /> */}
        <About data={landingPageData.About} />
        {/* <Services data={landingPageData.Services} /> */}
        <Gallery data={landingPageData.Gallery}/>
        <Testimonials data={landingPageData.Testimonials} />
        <Team data={landingPageData.Team} />
        <Contact data={landingPageData.Contact} />
      </div>
      {/* <ToastContainer
        theme="dark"
        position="top-center"
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
      /> */}
    </>
  );
};

export default App;
